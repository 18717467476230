import React from "react";
import { useMemo } from "react";
import TileCard from "./TileCard";
import { tileAvg } from "src/utils/number";
import { useTranslation } from "react-i18next";

const MachineMSF = ({ isLoading, MSFData, chartData }) => {
  const msfAvg = useMemo(() => {
    if (!Object.keys(MSFData)?.length) return "--";
    return tileAvg(MSFData, Object.keys(MSFData).length);
  }, [MSFData]);
  const { t } = useTranslation(["glossary", "common"]);

  if (isLoading) {
    return <TileCard isSkeleton />;
  }

  return (
    <TileCard
      label={t("msf")}
      units={msfAvg || msfAvg === 0 ? t("per/hr") : ""}
      value={msfAvg || msfAvg === 0 ? msfAvg : "--"}
      chartData={chartData}
    />
  );
};

export default MachineMSF;
