import {
  Button,
  Card,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  Paper,
  TextField,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Skeleton } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FeatureNotAvailable from "src/components/FeatureNotAvailable";
import AddMaintenanceCard from "src/components/Maintenance/AddMaintenanceCard";
import MaintenanceCard from "src/components/Maintenance/MaintenanceCard";
import MaintenanceDetail from "src/components/Maintenance/MaintenanceDetail";
import MaintenanceOnboarding from "src/components/Maintenance/MaintenanceOnboarding";
import Page from "src/components/Page";
import {
  createMaintenance,
  deleteMaintenance,
  getDeviceMaintenances,
  updateMaintenance,
} from "src/repos/maintenances";
import { useQueryParam } from "src/utils/hooks";
import { isAdmin, isOEMSuperUser } from "src/utils/session";
import { isAllowed } from "src/utils/tier_permissions";
import { Trans, useTranslation } from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  card: { padding: "24px 32px" },
  container: {
    padding: 0,
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  list: {
    alignContent: "flex-start",
  },
  cardList: {
    maxHeight: "78vh",
    overflow: "auto",
  },
  select: {
    minWidth: 300,
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(5),
    borderRadius: "10px",
  },
  notAllowedOverlay: {
    position: "absolute",
    top: "50%",
    left: "25%",
    transform: "translate(-17.5%, -50%)",
    zIndex: 10,
  },
  noBlur: { maxWidth: "100%" },
  blur: { maxWidth: "100%", filter: "blur(3px)", pointerEvents: "none" },
}));

function Overview({ machine: device }) {
  // id => device serial number
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const maintenanceSortOrder =
    localStorage.getItem("maintenancesSortOrder") || "due_date-asc";
  const [storedSort, storedOrder] = maintenanceSortOrder.split("-");
  const { t } = useTranslation(["glossary", "common"]);

  const [maintenanceList, setMaintenanceList] = useState([]);
  const [unusedTypesList, setUnusedTypesList] = useState([]);
  const [selectedMaintenanceId, setSelectedMaintenanceId] = useQueryParam(
    "selected",
    null
  );
  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);
  const [addMaintenanceModalOpen, setAddMaintenanceModalOpen] = useState(false);
  const [
    addTransmissionLifeModalOpen,
    setAddTransmissionLifeModalOpen,
  ] = useState(false);
  const [
    addCustomMaintenanceModalOpen,
    setAddCustomMaintenanceModalOpen,
  ] = useState(false);
  const [maintenanceToAdd, setMaintenanceToAdd] = useState({});
  const [checkedMaintenance, setCheckedMaintenance] = useState({});
  const [sortOption, setSortOption] = useState(storedSort);
  const [orderOption, setOrderOption] = useState(storedOrder);
  const isUnlimited = isAllowed("unlimitedMaintenances", device?.service_tier);
  const [isLoading, setIsLoading] = useState(true);
  const [doubleUp, setDoubleUp] = useState(false);
  const [customName, setCustomName] = useState(null);
  const [customMaxTagThreshold, setCustomMaxTagThreshold] = useState(null);
  const [cutoffValue, setCutoffValue] = useState(null);
  const [customNameError, setCustomNameError] = useState("");
  const sorter =
    sortOption === "due_date"
      ? [
          [
            (maintenance) => {
              return maintenance.last_performed_at !== null;
            },
            (maintenance) => {
              return maintenance.recommended_date;
            },
          ],
          [orderOption, orderOption],
        ]
      : ["name", orderOption];
  const maintenancesByID = _.reduce(
    maintenanceList,
    (a, m) => {
      a[m.id] = m;
      return a;
    },
    {}
  );

  const setMaintenances = ({ maintenances, available_types }) => {
    setMaintenanceList(_.orderBy(maintenances, ...sorter));
    const unusedTypes = _.differenceBy(
      available_types,
      maintenances,
      "kind"
    ).filter(
      (type) =>
        type.kind !== "Elixir.HeliosCloud.Maintenances.CustomMaintenance"
    );
    const customMaintenances = available_types.filter(
      (type) =>
        type.kind === "Elixir.HeliosCloud.Maintenances.CustomMaintenance"
    );
    const combinedUnusedTypesList = [...unusedTypes, ...customMaintenances];
    setUnusedTypesList(combinedUnusedTypesList);
  };

  useEffect(() => {
    getDeviceMaintenances(id)
      .then((r) => r.json())
      .then(setMaintenances)
      .then(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    localStorage.setItem(
      "maintenancesSortOrder",
      `${sortOption}-${orderOption}`
    );
    setMaintenanceList((maintenances) => {
      return _.orderBy(maintenances, ...sorter);
    });
    // eslint-disable-next-line
  }, [sortOption, orderOption]);

  useEffect(() => {
    if (
      !_.isEmpty(maintenancesByID) &&
      !maintenancesByID[selectedMaintenanceId]
    )
      setSelectedMaintenanceId(maintenanceList?.[0]?.id);
    // eslint-disable-next-line
  }, [maintenanceList, maintenancesByID]);

  const handleChange = (id) => {
    setSelectedMaintenanceId(id);
  };

  const handleMaintenanceModalOpen = (maintenance) => {
    setCheckedMaintenance(maintenance);
    setMaintenanceModalOpen(true);
  };

  const handleMaintenanceModalClose = () => {
    setCheckedMaintenance({});
    setMaintenanceModalOpen(false);
  };

  const handleAddMaintenanceModalOpen = () => {
    setAddMaintenanceModalOpen(true);
  };

  const handleAddMaintenanceModalClose = () => {
    setAddMaintenanceModalOpen(false);
  };

  const handleAddTransmissionLifeModalOpen = () => {
    setAddTransmissionLifeModalOpen(true);
  };

  const handleAddTransmissionLifeModalClose = () => {
    setAddTransmissionLifeModalOpen(false);
  };

  const handleAddCustomMaintenanceModalOpen = () => {
    setAddCustomMaintenanceModalOpen(true);
  };

  const handleAddCustomMaintenanceModalClose = () => {
    setAddCustomMaintenanceModalOpen(false);
  };

  const handleAddTransmissionLifeMaintenance = () => {
    setAddTransmissionLifeModalOpen(false);
    handleAddMaintenance();
  };

  const handleAddCustomMaintenance = () => {
    const isDuplicateName = maintenanceList.some(
      (maintenance) =>
        maintenance.name.toLowerCase() === customName.toLowerCase()
    );

    if (isDuplicateName) {
      setCustomNameError(
        `A maintenance with the name "${customName}" already exists.`
      );
    } else {
      handleAddCustomMaintenanceModalClose();
      handleAddMaintenance();
    }
  };

  const isCustomMaintenance = () => {
    setAddMaintenanceModalOpen(false);
    if (!_.isEmpty(maintenanceToAdd)) {
      const kind = maintenanceToAdd.value;
      if (kind === 0) {
        handleAddTransmissionLifeModalOpen();
      } else if (kind === 61) {
        handleAddCustomMaintenanceModalOpen();
      } else {
        handleAddMaintenance();
      }
    }
  };

  const handleDeleteMaintenance = (maintenanceID) => {
    deleteMaintenance(maintenanceID, true)
      .then(() => getDeviceMaintenances(id))
      .then((r) => r.json())
      .then(setMaintenances);
    if (selectedMaintenanceId === maintenanceID) setSelectedMaintenanceId("");
  };

  const handleAddMaintenance = () => {
    if (!_.isEmpty(maintenanceToAdd)) {
      const kind = maintenanceToAdd.value;
      let doubleUpValue = null;
      if (kind === 0) {
        doubleUpValue = doubleUp;
      } else if (kind === 50) {
        doubleUpValue = false;
      }
      createMaintenance(
        id,
        kind,
        doubleUpValue,
        customName,
        cutoffValue,
        customMaxTagThreshold * 24
      )
        .then((r) => r.json())
        .then(({ data: maintenance, errors }) => {
          if (errors) {
            console.log("An error has occurred");
          } else {
            getDeviceMaintenances(id)
              .then((r) => r.json())
              .then((data) => {
                setMaintenances(data);
                setSelectedMaintenanceId(maintenance.id);
              });
          }
        });
      setMaintenanceToAdd({});
    }
    setAddMaintenanceModalOpen(false);
    setCustomName(null);
    setCustomMaxTagThreshold(null);
    setCutoffValue(null);
  };

  const handleSnoozeMaintenance = (maintenanceId, snooze_date) => {
    updateMaintenance({
      id: maintenanceId,
      snoozed_at: snooze_date,
    })
      .then(() => getDeviceMaintenances(id))
      .then((r) => r.json())
      .then(setMaintenances);
  };

  const markMaintenanceAsDone = (
    maintenanceId,
    performedAt,
    predictionAccuracy,
    doubleUp
  ) => {
    if (_.isNil(maintenanceId)) return;

    updateMaintenance({
      id: maintenanceId,
      performed_at: performedAt,
      prediction_accuracy: predictionAccuracy,
      device_serial: id,
      double_up: doubleUp,
    })
      .then((r) => r.json())
      .then(({ data: maintenance }) => {
        getDeviceMaintenances(id)
          .then((r) => r.json())
          .then((d) => {
            setMaintenances(d);
            if (maintenanceId === selectedMaintenanceId) {
              maintenance = _.find(d.maintenances, (m) => {
                return m.name === maintenance.name;
              });
              setSelectedMaintenanceId(maintenance ? maintenance.id : "");
            }
            handleMaintenanceModalClose();
          });
      });
  };

  const maintenanceStatus = (maintenance) => {
    if (
      maintenance.snoozed_at &&
      moment().diff(moment(maintenance.snoozed_at), "days") < 30
    ) {
      return "Snoozed";
    }

    if (
      [...maintenance.burn_rates]?.pop()?.system_time >
      maintenance.recommended_date
    ) {
      return "Overdue";
    } else if (
      moment(maintenance.recommended_date).diff(moment(), "days") < 30
    ) {
      return "Due Soon";
    } else if (_.isEmpty(maintenance.history)) {
      return "New";
    }
    return "Default";
  };

  const currentValue = (maintenance) => {
    if (!_.isEmpty(maintenance.history) && _.isEmpty(maintenance.burn_rates)) {
      return 100;
    } else {
      return (
        !_.isEmpty(maintenance.burn_rates) &&
        [...maintenance.burn_rates].pop().value
      );
    }
  };

  const sortComponent = (
    <Grid item xs={12}>
      <Paper style={{ padding: "24px 32px" }}>
        <FormControl variant="outlined" component="fieldset">
          <InputLabel id="sort-select-label">Sort By</InputLabel>
          <Select
            labelId="sort-select-label"
            id="sort-select"
            value={`${sortOption}-${orderOption}`}
            onChange={(e) => {
              const [sort, order] = e.target.value.split("-");
              setSortOption(sort);
              setOrderOption(order);
            }}
            label={t("sort_by")}
          >
            <MenuItem value={"due_date-asc"}>
              {t("estimated_date_ascending")}
            </MenuItem>
            <MenuItem value={"due_date-desc"}>
              {t("estimated_date_descending")}
            </MenuItem>
            <MenuItem value={"name-asc"}>{t("name_ascending")}</MenuItem>
            <MenuItem value={"name-desc"}>{t("name_descending")}</MenuItem>
          </Select>
        </FormControl>
      </Paper>
    </Grid>
  );

  const upsellText = t("maintenance_upsell_text");
  return (
    <Page className={classes.root} title="Maintenance">
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={2}>
          {isMobile ? (
            <>
              {/* Does this make sense for mobile view? */
              /* sortComponent */}
              <Grid item xs={12} className={classes.list}>
                <Card>
                  <CardHeader title={t("select_maintenance")} />

                  <Select
                    value={selectedMaintenanceId}
                    onChange={(e) => handleChange(e.target.value)}
                    variant="outlined"
                    color="primary"
                    className={classes.select}
                  >
                    {maintenanceList.map((maintenance) => (
                      <MenuItem value={maintenance.id} key={maintenance.id}>
                        {maintenance.name}
                      </MenuItem>
                    ))}
                    {!_.isEmpty(unusedTypesList) &&
                      (isAdmin() || isOEMSuperUser()) && (
                        <MenuItem
                          value={-1}
                          onClick={handleAddMaintenanceModalOpen}
                        >
                          <ListItemIcon>
                            <AddCircleIcon fontsize="sm" />
                          </ListItemIcon>
                          {t("add_new_maintenance")}
                        </MenuItem>
                      )}
                  </Select>
                </Card>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} lg={3} className={classes.cardList}>
              <Grid container spacing={2}>
                {(!_.isEmpty(maintenanceList) || !_.isEmpty(unusedTypesList)) &&
                  sortComponent}
                {!_.isEmpty(unusedTypesList) &&
                  (isAdmin() || isOEMSuperUser()) &&
                  !isLoading && (
                    <AddMaintenanceCard
                      name={"add a Maintenance"}
                      onClick={handleAddMaintenanceModalOpen}
                    ></AddMaintenanceCard>
                  )}
                {isLoading
                  ? Array.apply(null, { length: 3 }).map((e, i) => (
                      <Grid item xs={12} key={i}>
                        <Paper
                          elevation={1}
                          style={{
                            padding: "20px",
                            marginBottom: theme.spacing(2),
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Skeleton
                              animation="wave"
                              variant="circle"
                              width={50}
                              height={50}
                            />
                            <Skeleton
                              animation="wave"
                              height={50}
                              style={{
                                flexGrow: 1,
                                marginLeft: theme.spacing(1),
                              }}
                            />
                          </div>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </Paper>
                      </Grid>
                    ))
                  : maintenanceList.map((maintenance) => (
                      <MaintenanceCard
                        key={maintenance.id}
                        id={maintenance.id}
                        name={maintenance.name}
                        verbs={maintenance.verbs}
                        type={maintenance.type}
                        suggested={maintenance.cutoff_value}
                        status={maintenanceStatus(maintenance)}
                        selected={selectedMaintenanceId === maintenance.id}
                        onClick={handleChange}
                        onCheckClick={() =>
                          handleMaintenanceModalOpen(maintenance)
                        }
                        estimatedDate={maintenance.recommended_date}
                        current={currentValue(maintenance)}
                        checked={maintenance.id === checkedMaintenance.id}
                      />
                    ))}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} lg={9}>
            {isLoading && (
              <Card className={classes.card}>
                <Skeleton height={50} />
                <Skeleton height={400} variant="rect" />
              </Card>
            )}
            {_.isEmpty(maintenanceList) &&
              _.isEmpty(unusedTypesList) &&
              !isLoading && (
                <DialogContentText>
                  <Trans
                    i18nKey="glossary:no_maintenances_message"
                    components={{
                      1: (
                        <a href="mailto:helios.support@sunautomation.com">
                          helios.support@sunautomation.com
                        </a>
                      ),
                    }}
                  />
                </DialogContentText>
              )}
            {!_.isEmpty(maintenanceList) &&
              !_.isEmpty(selectedMaintenanceId) &&
              maintenancesByID[selectedMaintenanceId] &&
              !isLoading && (
                <MaintenanceDetail
                  details={maintenanceList.find(
                    (maintenance) => selectedMaintenanceId === maintenance.id
                  )}
                  onCheckClick={() =>
                    handleMaintenanceModalOpen(
                      maintenanceList.find(
                        (maintenance) =>
                          selectedMaintenanceId === maintenance.id
                      )
                    )
                  }
                  onDeleteConfirm={handleDeleteMaintenance}
                  onMarkMaintenanceAsDone={markMaintenanceAsDone}
                  getMaintenanceStatus={maintenanceStatus}
                  onSnoozeConfirm={handleSnoozeMaintenance}
                />
              )}
          </Grid>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={maintenanceModalOpen}
          onClose={handleMaintenanceModalClose}
          closeAfterTransition
        >
          <Fade in={maintenanceModalOpen}>
            <div className={classes.paper}>
              <MaintenanceOnboarding
                id={checkedMaintenance.id}
                name={checkedMaintenance.name}
                verbs={checkedMaintenance.verbs}
                target={checkedMaintenance.target}
                actions={checkedMaintenance.actions}
                onClick={markMaintenanceAsDone}
                lastPerformedAt={_.max(
                  maintenanceList
                    .find(
                      (maintenance) => selectedMaintenanceId === maintenance.id
                    )
                    ?.history?.map((h) => h.performed_at)
                )}
              />
            </div>
          </Fade>
        </Modal>
        <Dialog
          open={addMaintenanceModalOpen}
          onClose={handleAddMaintenanceModalClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          {!isUnlimited && maintenanceList.length >= 3 && (
            <Card className={classes.notAllowedOverlay}>
              <FeatureNotAvailable upsellText={upsellText} />
            </Card>
          )}
          <div
            className={
              !isUnlimited && maintenanceList.length >= 3
                ? classes.blur
                : classes.noBlur
            }
          >
            <DialogTitle>{t("new_maintenance_prediction")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("select_maintenance_2")}</DialogContentText>
              <Autocomplete
                variant="outlined"
                name="maintenance"
                style={{ width: "100%" }}
                options={unusedTypesList}
                getOptionDisabled={(option) =>
                  !isUnlimited && !option.basic_type
                }
                getOptionLabel={(option) => option.name}
                value={maintenanceToAdd}
                onChange={(event, newValue) => {
                  setMaintenanceToAdd(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("maintenances")}
                    variant="outlined"
                  />
                )}
              ></Autocomplete>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleAddMaintenanceModalClose}
                color="secondary"
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                disabled={_.isEmpty(maintenanceToAdd)}
                onClick={isCustomMaintenance}
                color="primary"
              >
                {t("add")}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          open={addCustomMaintenanceModalOpen}
          onClose={handleAddCustomMaintenanceModalClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>{t("new_custom_maintenance_prediction")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("custom_maintenance_details")}
            </DialogContentText>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  label={t("name")}
                  variant="outlined"
                  id="name"
                  name="name"
                  required
                  fullWidth={true}
                  value={customName}
                  onChange={(event) => {
                    setCustomName(event.target.value);
                    setCustomNameError("");
                  }}
                  error={customNameError.length > 0}
                  helperText={customNameError || ""}
                />
              </Grid>
              <Grid item>
                <TextField
                  label={t("estimated_part_lifetime")}
                  variant="outlined"
                  id="esimated_part_lifetime"
                  name="estimated_part_lifetime"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {t("days")}
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  value={customMaxTagThreshold}
                  onChange={(event) =>
                    setCustomMaxTagThreshold(Number(event.target.value))
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  label={t("cutoff_percentage")}
                  variant="outlined"
                  id="cutoff_value"
                  name="cutoff_value"
                  required
                  type="number"
                  value={cutoffValue}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    if (newValue === "") {
                      setCutoffValue("");
                    } else {
                      const numValue = Number(newValue);
                      if (numValue >= 0 && numValue <= 100) {
                        setCutoffValue(numValue);
                      }
                    }
                  }}
                />
                <Tooltip title={t("cutoff_percentage_tooltip")}>
                  <HelpIcon
                    style={{
                      display: "inline",
                      fontSize: 16,
                      marginLeft: 3,
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleAddCustomMaintenanceModalClose}
              color="secondary"
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              disabled={
                customName === null ||
                customName === "" ||
                customMaxTagThreshold === null ||
                customMaxTagThreshold === "" ||
                cutoffValue === null ||
                cutoffValue === ""
              }
              onClick={handleAddCustomMaintenance}
              color="primary"
            >
              {t("add")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={addTransmissionLifeModalOpen}
          onClose={handleAddTransmissionLifeModalClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>{t("new_transmission_life_prediction")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("transmission_life_details")}
            </DialogContentText>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doubleUp}
                        onChange={(e) => setDoubleUp(e.target.checked)}
                        name="double_up"
                        color="primary"
                      />
                    }
                    label={t("double_up_equipped")}
                  />
                </>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleAddTransmissionLifeModalClose}
              color="secondary"
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleAddTransmissionLifeMaintenance}
              color="primary"
            >
              {t("add")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}

export default Overview;
