import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/styles";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import * as ChartZoom from "chartjs-plugin-zoom";
import _ from "lodash";
import moment from "moment";
import pattern from "patternomaly";
import PropTypes from "prop-types";
import React, { createRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import ButtonConfirm from "src/components/ButtonConfirm";
import MaintenanceOnboarding from "src/components/Maintenance/MaintenanceOnboarding";
import palette from "src/theme/palette";
import { isOEMSuperUser, isSuperUser } from "src/utils/session";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: { padding: "24px 32px" },

  divider: {
    margin: "24px 0",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },

  header: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    alignItems: "flex-start",
  },

  flex: { display: "flex" },

  form: {
    marginLeft: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  gutterRight: {
    marginRight: theme.spacing(3),
  },
  chartContainer: {
    height: "38vh",
  },
  historyContainer: {
    marginTop: 75,
  },
}));

function MaintenanceDetail(props) {
  const classes = useStyles();
  const details = props.details;
  const chartRef = createRef();
  const status = props.getMaintenanceStatus(props.details);
  const { t } = useTranslation(["glossary", "common"]);

  useEffect(() => {
    // reset chart zoom originalOptions on data change
    if (chartRef?.current?.chartInstance?.$zoom) {
      chartRef.current.chartInstance.$zoom._originalOptions = {};
    }
  }, [details, chartRef]);

  const estimationData = [
    {
      x: details.burn_rates?.[0]?.system_time,
      y: details.burn_rates?.[0]?.value,
    },
    { x: details.recommended_date, y: details.cutoff_value },
  ];

  const chartData = details.burn_rates
    ? details.burn_rates.map((rate) => ({ x: rate.system_time, y: rate.value }))
    : {};

  const data = {
    datasets: [
      {
        label: t("burndown"),
        fill: false,
        lineTension: 0.5,
        backgroundColor: palette.primary.main,
        borderColor: palette.primary.main,
        borderWidth: 2,
        pointRadius: 0,
        data: chartData,
      },
      {
        label: t("estimation"),
        fill: false,
        lineTension: 0.5,
        borderDash: [10, 2],
        pointRadius: 0,
        borderColor: palette.gray.lighter,
        borderWidth: 2,
        data: estimationData,
      },
    ],
  };

  const clicksToResetZoom = 2;

  const handleChartClick = (e) => {
    // reset chart zoom on double click
    if (e.detail === clicksToResetZoom) {
      chartRef.current.chartInstance.resetZoom();
    }
  };

  const minValue = () => {
    const closestStep = Math.floor(_.last(details.burn_rates)?.value / 20) * 20;
    return _.min([0, closestStep]);
  };

  const maxDate = () => {
    const now = moment();
    const recommendedDate = moment(details.recommended_date).isValid()
      ? moment(details.recommended_date)
      : now;

    return moment.max(recommendedDate, now);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    onClick: handleChartClick,
    onHover: (e) => (e.target.style.cursor = "zoom-in"),
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          lineTension: 0.5,
          borderDash: [10, 2],
          pointRadius: 0,
          value: details.cutoff_value,
          borderColor: palette.status.error.main,
          borderWidth: 2,
          label: {
            enabled: false,
            content: t("cutoff_value"),
          },
        },
        {
          drawTime: "beforeDatasetsDraw",
          type: "box",
          xScaleID: "top-x-axis",
          backgroundColor: pattern.draw("diagonal", "rgb(234, 241, 247, 0.8)"),
          borderColor: "rgb(234, 241, 247)",
          xMin: details?.last_performed_at,
          xMax: details.burn_rates?.[0]?.system_time,
        },
      ],
    },

    scales: {
      xAxes: [
        {
          id: "top-x-axis",
          position: "bottom",
          type: "time",
          distribution: "linear",
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            min: details.last_performed_at,
            max: maxDate(),
            beginAtZero: true,
            padding: 20,
            fontColor: palette.text.secondary,
            maxTicksLimit: 12,
          },
          time: {
            displayFormats: {
              millisecond: "MM/DD/YY h:mm a",
              second: "MM/DD/YY h:mm a",
              day: "MM/DD/YY",
            },
          },
        },
      ],

      yAxes: [
        {
          ticks: {
            min: minValue(),
            max: 100,
          },
        },
      ],
    },
    plugins: {
      zoom: {
        zoom: {
          enabled: true,
          mode: "x",
          drag: true,
        },
      },
    },
  };

  const formatPredictionAccuracy = (predictionAccuracy) => {
    const values = {
      on_time: t("right_on_time"),
      early: t("too_early"),
      late: t("too_late"),
    };

    return values[predictionAccuracy] || "--";
  };

  const displaySnoozeButton = () => {
    if (details.snoozed_at) {
      return moment().diff(moment(details.snoozed_at), "days") > 30;
    }

    return status === "Overdue";
  };

  const displayUnsnoozeButton = () => {
    if (details.snoozed_at) {
      return moment().diff(moment(details.snoozed_at), "days") < 30;
    }

    return false;
  };

  return (
    <Grid item sm={12} className={classes.root}>
      <Card className={classes.card}>
        {details.last_performed_at && details.recommended_date ? (
          <CardHeader
            title={details.name}
            className={classes.header}
            subheader={
              <div className={classes.header}>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.gutterRight}
                >
                  {t("last")} {_.capitalize(details.verbs.noun)}:{" "}
                  {moment(details.last_performed_at).format("ll")}
                </Typography>
                <Typography gutterBottom variant="subtitle1">
                  {t("suggested")} {_.capitalize(details.verbs.noun)}:{" "}
                  {moment(details.recommended_date).format("ll")}
                </Typography>
              </div>
            }
            action={
              <>
                {(isSuperUser() || isOEMSuperUser()) && (
                  <>
                    <ButtonConfirm
                      color="primary"
                      variant="contained"
                      size="medium"
                      onConfirm={() => {
                        props.onDeleteConfirm(details.id);
                      }}
                      buttonText={t("delete")}
                      dialogLabel={t("delete_confirmation", {
                        name: details.name,
                      })}
                      dialogText={t("delete_maintenance_warning")}
                    />
                    <Button
                      style={{ marginLeft: "10px" }}
                      color="secondary"
                      variant="contained"
                      size="medium"
                      onClick={props.onCheckClick}
                    >
                      {t("mark_as_done")}
                    </Button>
                    {displaySnoozeButton() && (
                      <ButtonConfirm
                        style={{ marginLeft: "10px" }}
                        color="default"
                        variant="contained"
                        size="medium"
                        onConfirm={() => {
                          props.onSnoozeConfirm(details.id, new Date());
                        }}
                        buttonText={t("snooze")}
                        dialogLabel={t("snooze_confirmation", {
                          name: details.name,
                        })}
                        dialogText={t("snooze_warning")}
                      >
                        {t("snooze")}
                      </ButtonConfirm>
                    )}
                    {displayUnsnoozeButton() && (
                      <ButtonConfirm
                        style={{ marginLeft: "10px" }}
                        color="secondary"
                        variant="contained"
                        size="medium"
                        onConfirm={() => {
                          props.onSnoozeConfirm(details.id, null);
                        }}
                        buttonText={t("unsnooze")}
                        dialogLabel={t("unsnooze_confirmation", {
                          name: details.name,
                        })}
                        dialogText={t("are_you_sure")}
                      >
                        {t("snooze")}
                      </ButtonConfirm>
                    )}
                  </>
                )}
              </>
            }
          />
        ) : (
          <CardHeader
            title={details.name}
            action={
              (isSuperUser() || isOEMSuperUser()) && (
                <ButtonConfirm
                  color="primary"
                  variant="contained"
                  size="medium"
                  onConfirm={() => {
                    props.onDeleteConfirm(details.id);
                  }}
                  buttonText={t("delete")}
                  dialogLabel={`Delete "${details.name}"?`}
                  dialogText={t("delete_maintenance_warning")}
                />
              )
            }
          />
        )}

        <Divider />
        <CardContent>
          {!_.isEmpty(details.history) ? (
            <div className={classes.chartContainer}>
              <Typography gutterBottom variant="h5">
                {t("burndown_chart")}
              </Typography>
              <Line
                ref={chartRef}
                data={data}
                options={options}
                plugins={[ChartAnnotation, ChartZoom]}
              />
              <Divider className={classes.divider} />
            </div>
          ) : (
            <MaintenanceOnboarding
              id={details.id}
              name={details.name}
              target={details.target}
              verbs={details.verbs}
              actions={details.actions}
              hidePrediction={_.isEmpty(details.history)}
              onClick={props.onMarkMaintenanceAsDone}
              lastPerformedAt={_.max(
                details.history?.map((h) => h.performed_at)
              )}
            />
          )}
          {
            // hiding this section for now, as we don't have the copys for it
            false && (
              <>
                <Typography gutterBottom variant="h5">
                  How to replace the transmission?
                </Typography>
                <Typography variant="subtitle1">
                  Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                  used in laying out print, graphic or web designs. The passage
                  is attributed to an unknown typesetter in the 15th century who
                  is thought to have scrambled parts of Cicero's De Finibus
                  Bonorum et Malorum for use in a type specimen book.
                </Typography>

                <Divider className={classes.divider} />
              </>
            )
          }
          <div className={classes.historyContainer}>
            <Typography gutterBottom variant="h5">
              {_.capitalize(details.verbs.noun)} {t("history")}
            </Typography>
            {!_.isEmpty(details.history) ? (
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("date")}</TableCell>
                      <TableCell>{t("status")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {details.history.map((history, key) => (
                      <TableRow key={key}>
                        <TableCell>
                          {moment(history.performed_at).format("ll")}
                        </TableCell>
                        <TableCell>
                          {formatPredictionAccuracy(
                            history.prediction_accuracy
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="subtitle1">
                {t("no_history", { noun: details.verbs.noun })}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}

MaintenanceDetail.propTypes = {
  details: PropTypes.object,
  onCheckClick: PropTypes.func,
  onMarkMaintenanceasDone: PropTypes.func,
};

export default MaintenanceDetail;
